/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import debounce from "debounce";
import moment from "moment";
import { useStore } from "store";
import validate from "../utils/validate";
import { formatInputValue } from "../helpers";
import View from "./View";

const Controller = (props) => {
    const {
        title,
        buttonText,
        createEvent,
        onClose,
        getType,
        getClass,
        getSubject,
        getSubjectAdd,
        getStudent,
        getStudentAdd,
        getTime,
    } = props;

    const {
        createLessonStore: {
            name,
            type_id,
            type_name,
            lesson_url,
            class_id,
            subject_id,
            students,
            start_at,
            start_time,
            is_add,
            setIsAdd,
            is_update,
            setIsUpdate,
        },
    } = useStore();

    const [data, setData] = useState({
        teacher_id: localStorage.getItem("id"),
        name: name,
        type_id: type_id,
        class_id: "",
        subject_id: "",
        students: null,
        start_at: start_at || "",
        start_time: start_time || "",
        lesson_url: lesson_url,
        description: null,
    });

    const [selectType, setSelectType] = useState(type_name || []);
    const [noSelectType, setNoSelectType] = useState([]);
    const [allSelectType, setAllSelectType] = useState([]);
    const [currentAllSelectType, setCurrentAllSelectType] = useState([]);
    const [loadingType, setLoadingType] = useState(false);

    const [selectSubject, setSelectSubject] = useState([]);
    const [noSelectSubject, setNoSelectSubject] = useState([]);
    const [allSelectSubject, setAllSelectSubject] = useState([]);
    const [loadingSubject, setLoadingSubject] = useState(false);
    const [subjectTotal, setSubjectTotal] = useState(0);
    const [subjectPage, setSubjectPage] = useState(1);
    const [subjectSearch, setSubjectSearch] = useState("");

    const [selectClass, setSelectClass] = useState([]);
    const [noSelectClass, setNoSelectClass] = useState([]);
    const [allSelectClass, setAllSelectClass] = useState([]);
    const [currentAllSelectClass, setCurrentAllSelectClass] = useState([]);
    const [loadingClass, setLoadingClass] = useState(false);

    const [studentSelect, setStudentSelect] = useState(students || []);
    const [noStudentSelect, setNoStudentSelect] = useState([]);
    const [allStudentSelect, setAllStudentSelect] = useState([]);
    const [studentLoading, setStudentLoading] = useState(false);
    const [studentTotal, setStudentTotal] = useState(0);
    const [studentPage, setStudentPage] = useState(1);
    const [studentSearch, setStudentSearch] = useState("");

    const [selectTime, setSelectTime] = useState([]);
    const [noSelectTime, setNoSelectTime] = useState([]);
    const [allSelectTime, setAllSelectTime] = useState([]);
    const [currentAllSelectTime, setCurrentAllSelectTime] = useState([]);
    const [loadingTime, setLoadingTime] = useState(false);

    const [errors, setErrors] = useState({});

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const onBlurSelect = async (name, data) => {
        const validationErrors = await validate.clientValidateField(name, {
            ...data,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const onBlur = async (event) => {
        const validationErrors = await validate.clientValidateField(event.target.name, {
            ...data,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const onScroll = (mas, total, setPage, page) => {
        if (mas.length < total) setPage(page + 1);
    };

    const onSearch = (setCurrent, allSelect) =>
        debounce((value) => {
            setCurrent(
                allSelect.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())),
            );
        }, 300);

    const onSearchFirstPage = (setPage, setSearch) =>
        debounce((value) => {
            setPage(1);
            setSearch(value);
        }, 300);

    useEffect(() => {
        setLoadingType(true);
        getType(
            setAllSelectType,
            setCurrentAllSelectType,
            setSelectType,
            setNoSelectType,
            setLoadingType,
        );

        setLoadingClass(true);
        getClass(setAllSelectClass, setCurrentAllSelectClass, setLoadingClass);
    }, []);

    data.students = useMemo(() => studentSelect.map((item) => item.selectedCode), [studentSelect]);

    data.start_time = useMemo(() => {
        if (selectTime.length > 0) {
            clearErrField("start_time");
            return selectTime[0].selectedCode;
        }
        return "";
    }, [selectTime]);

    useEffect(() => {
        if (selectType.length > 0) {
            setData({ ...data, type_id: selectType[0].selectedCode });
            clearErrField("type_id");
        } else {
            setData({ ...data, type_id: "" });
        }
        if (!is_update) {
            setStudentSelect([]);
            data.students = [];
        }
        setIsUpdate(is_update);
    }, [selectType]);

    useEffect(() => {
        if (
            data.start_at !== "" &&
            data.type_id !== "" &&
            data.students?.length !== 0 &&
            data.teacher_id !== ""
        ) {
            setLoadingTime(true);
            getTime(
                data.students,
                data.teacher_id,
                data.start_at,
                data.type_id,
                setAllSelectTime,
                setSelectTime,
                setCurrentAllSelectTime,
                setLoadingTime,
                start_time,
            );
        }
    }, [data.start_at, data.type_id, data.students, data.teacher_id]);

    useEffect(() => {
        setLoadingSubject(true);
        if (subjectPage === 1) {
            getSubject(
                subjectSearch,
                subjectPage,
                setAllSelectSubject,
                setSubjectTotal,
                setLoadingSubject,
            );
        } else {
            getSubjectAdd(
                subjectSearch,
                subjectPage,
                setAllSelectSubject,
                setSubjectTotal,
                setLoadingSubject,
                allSelectSubject,
            );
        }
    }, [subjectPage, subjectSearch]);

    useEffect(() => {
        if (selectSubject.length > 0) {
            setData({ ...data, subject_id: selectSubject[0].selectedCode });
        } else {
            setData({ ...data, subject_id: "" });
        }
    }, [selectSubject]);

    useEffect(() => {
        if (selectClass.length > 0) {
            setData({ ...data, class_id: selectClass[0].selectedCode });
        } else {
            setData({ ...data, class_id: "" });
        }
    }, [selectClass]);

    useEffect(() => {
        if (studentSelect.length > 0) {
            setData({
                ...data,
                student: studentSelect.map((student) => student.selectedCode),
                start_time: "",
            });
            clearErrField("students");
        } else {
            setData({ ...data, student: null, start_time: "" });
        }
    }, [studentSelect]);

    useEffect(() => {
        setStudentLoading(true);
        if (studentPage === 1) {
            getStudent(
                studentSearch,
                studentPage,
                setAllStudentSelect,
                setStudentTotal,
                setStudentLoading,
            );
        } else {
            getStudentAdd(
                studentSearch,
                studentPage,
                setAllStudentSelect,
                setStudentTotal,
                setStudentLoading,
                allStudentSelect,
            );
        }
    }, [studentPage, studentSearch]);

    const onSearchType = useMemo(
        () => onSearch(setCurrentAllSelectType, allSelectType),
        [allSelectType],
    );

    const onSearchClass = useMemo(
        () => onSearch(setCurrentAllSelectClass, allSelectClass),
        [allSelectClass],
    );

    const onSearchSubject = useMemo(() => onSearchFirstPage(setSubjectPage, setSubjectSearch), []);

    const onSearchStudent = useMemo(() => onSearchFirstPage(setStudentPage, setStudentSearch), []);

    const onSearchTime = useMemo(
        () => onSearch(setCurrentAllSelectTime, allSelectTime),
        [allSelectTime],
    );

    const inputValueType = selectType.length > 0 ? formatInputValue(selectType) : "Тип события";

    const inputValueClass = selectClass.length > 0 ? formatInputValue(selectClass) : "Класс";

    const inputValueSubject =
        selectSubject.length > 0 ? formatInputValue(selectSubject) : "Предмет";

    const studentInput = studentSelect.length > 0 ? formatInputValue(studentSelect) : "Все ученики";

    const inputValueTime = selectTime.length > 0 ? formatInputValue(selectTime) : "Время";

    const typeData = {
        selectType: selectType,
        setSelectType: setSelectType,
        noSelectType: noSelectType,
        setNoSelectType: setNoSelectType,
        allSelectType: currentAllSelectType,
        loadingType: loadingType,
        onSearchType: (value) => onSearchType(value),
        inputValueType: inputValueType,
    };

    const subjectData = {
        selectSubject: selectSubject,
        setSelectSubject: setSelectSubject,
        noSelectSubject: noSelectSubject,
        setNoSelectSubject: setNoSelectSubject,
        allSelectSubject: allSelectSubject,
        loadingSubject: loadingSubject,
        inputValueSubject: inputValueSubject,
        onSubjectScroll: () =>
            onScroll(allSelectSubject, subjectTotal, setSubjectPage, subjectPage),
        onSubjectSearch: (value) => onSearchSubject(value),
    };

    const classData = {
        selectClass: selectClass,
        setSelectClass: setSelectClass,
        noSelectClass: noSelectClass,
        setNoSelectClass: setNoSelectClass,
        allSelectClass: currentAllSelectClass,
        loadingClass: loadingClass,
        onSearchClass: (value) => onSearchClass(value),
        inputValueClass: inputValueClass,
    };

    const studentData = {
        studentAllTitle: "Все ученики",
        studentSelect: studentSelect,
        setStudentSelect: setStudentSelect,
        noStudentSelect: noStudentSelect,
        setNoStudentSelect: setNoStudentSelect,
        allStudentSelect: allStudentSelect,
        studentLoading: studentLoading,
        onStudentScroll: () =>
            onScroll(allStudentSelect, studentTotal, setStudentPage, studentPage),
        onStudentSearch: (value) => onSearchStudent(value),
        studentInput: studentInput,
    };

    const timeData = {
        selectTime: selectTime,
        setSelectTime: setSelectTime,
        noSelectTime: noSelectTime,
        setNoSelectTime: setNoSelectTime,
        allSelectTime: currentAllSelectTime,
        loadingTime: loadingTime,
        onSearchTime: (value) => onSearchTime(value),
        inputValueTime: inputValueTime,
    };

    const minDate = moment(Date()).format("yyyy-MM-DD");

    const checkCreateEvent = async () => {
        const validateErrors = await validate.clientValidateForm({
            ...data,
        });
        if (validateErrors) {
            setErrors({ ...validateErrors });
        } else {
            createEvent(data, setErrors);
        }
    };

    return (
        <View
            title={title}
            buttonText={buttonText}
            createEvent={() => checkCreateEvent()}
            onClose={onClose}
            clearErrField={clearErrField}
            data={data}
            setData={setData}
            errors={errors}
            onBlur={onBlur}
            onBlurSelect={(name) => onBlurSelect(name)}
            {...typeData}
            {...subjectData}
            {...classData}
            {...studentData}
            minDate={minDate}
            {...timeData}
        />
    );
};

export default Controller;
