import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import history from "../../utils/history/history";
import mostikLogo from "../../assets/img/mostikLogo.svg";
import authApi from "../../api/endPoints/login";
import * as style from "./style/Header.module.scss";
import { useStore } from "../../store";
import paths from "constants/paths";
import { useLocation } from "react-router";

function Header() {
  const {
    userStore: { isAuthorized, setIsAuthorized },
  } = useStore();
  const { pathname } = useLocation();

  const role = useMemo(() => {
    return localStorage.getItem("role");
  }, [isAuthorized]);

  const roleInSystem = { ADMIN: "Администратор", MENTOR: "Педагог" };

  const logOut = () => {
    authApi.logOut().then(() => {
      setIsAuthorized(false);
      history.push("/");
    });
  };

  const logoStyle = useMemo(() => {
    return `${style.headerLogo} ${
      pathname !== "/" ? style.headerLogo_click : ""
    }`;
  }, [pathname]);

  const onLogoCkick = () => {
    if (pathname !== "/") {
      history.push(paths.adminPanel);
    }
  };

  return (
    <div className={style.header}>
      <img
        src={mostikLogo}
        alt="Logo"
        className={logoStyle}
        onClick={onLogoCkick}
      />
      {isAuthorized ? (
        <div className={style.logoutWrapper}>
          <div className={style.userInfo}>
            <div className={style.userRole}>{roleInSystem[role]}</div>
          </div>
          <Button className={style.btn_link} color="link" onClick={logOut}>
            Выйти
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default observer(Header);
