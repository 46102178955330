import axiosInstance from "..";
import apiList from "../../constants/apiList";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getStudentClasses() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.educationClasses,
            });
            return response.data.classes;
        } catch (error) {
            throw error.response;
        }
    },
};
