import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";

import {
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { FormSelect } from "react-bootstrap";

import userApi from "../../../../api/endPoints/users";

import * as style from "./style/AddStudent.module.scss";
import downloadLogo from "../../../../assets/img/downloadLogo.svg";
import imgIcon from "../../../../assets/img/imgIcon.svg";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";

import {
  userActions,
  userReducer,
  userInitialValue,
} from "./reducers/userReducer.js";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import validation from "components/UsersList/EditUser/EditStudent/utils/validation";
import paths from "constants/paths";

import CropperModal from "../../../Popup/CropperModal";

import age from "utils/detectAge";

function AddStudent({ setCreateStudentData, createStudentData }) {
  const {
    userStore: { setUserListTabs, setOpenModalLeaveOpen },
    modalWindowStore: { cropperModalOpen, seCropperModalOpen },
  } = useStore();
  const [user, userDispatch] = useReducer(userReducer, userInitialValue);
  const [isCropperButtonPressed, setIsCropperButtonPressed] = useState(false);

  const [isSubmitBtnPressed, setIsSubmitBtnPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [divergencies, setDivergencies] = useState();
  const [studentClasses, setStudentClasses] = useState();
  const [studentEducationForm, setStudentEducationForm] = useState();
  const [adultRole, setAdultRole] = useState();
  const [city, setCity] = useState([]);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();
  const [buttonStatus, setButtonStatus] = useState(false);
  const imageElement = document.getElementById("avatar");
  const [cropPhoto, setCropPhoto] = useState();
  const [errors, setErrors] = useState({});
  const [searchCity, setSearchCity] = useState([""]);
  const [avatarName, setAvatarName] = useState("");

  const history = useHistory();

  useEffect(() => {
    userApi.getStudentDevergency().then((response) => {
      setDivergencies(response);
    });
    userApi.getStudentClass().then((response) => {
      setStudentClasses(response);
    });
    userApi.getStudentEducationForm().then((response) => {
      setStudentEducationForm(response);
    });
    userApi.getAdultRole().then((response) => {
      setAdultRole(response);
    });
  }, []);

  const handleCitySearch = (value) => {
    userApi
      .getCities(value)
      .then((result) => setCity(result.map((city) => city.name)));
  };

  // const handleCityBlur = async () => {
  //   if (user.adult.location) {
  //     setSearchCity([user.adult.location]);
  //   } else {
  //     setSearchCity(['']);
  //   }
  //   const validateErrors = await validation.clientValidateField('adult.location', {
  //     adult: { ...user.adult },
  //   });
  //   if (validateErrors) {
  //     setErrors({
  //       ...errors,
  //       ...validateErrors,
  //     });
  //   }
  // };

  // const handleCityChange = (selected, userType) => {
  //   clearErrField('adult', 'location');
  //   if (selected.length) {
  //     userDispatch({
  //       type: userActions.setValue,
  //       payload: { userType, name: formNames.location, value: selected[0] },
  //     });
  //   }
  //   setSearchCity(selected);
  // };

  const SIZE_LIMIT = 10485760;

  const formNames = {
    first_name: "first_name",
    last_name: "last_name",
    email: "email",
    birthdayStudent: "birthday",
    phone: "phone",
    education_class: "education_class",
    education_format: "education_format",
    divergency: "divergency",
    fio: "fio",
    birthdayAdult: "birthday",
    role: "role",
    location: "location",
    other_divergency: "other_divergency",
    avatar: "avatar",
  };

  const userTypes = {
    student: "student",
    adult: "adult",
  };

  const onBlur = async (e, userType) => {
    const { name } = e.target;
    const validationErrors = await validation.clientValidateField(
      `${userType}.${name}`,
      {
        [userType]: { ...user[userType] },
      }
    );

    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const onBlurPhone = async (e, userType) => {
    const validationErrors = await validation.clientValidateField(
      `${userType}.phone`,
      {
        [userType]: { ...user[userType] },
      }
    );

    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const clearErrField = (obj, field) => {
    const checkError = { ...errors };

    if (checkError[`${obj}.${field}`]) {
      delete checkError[`${obj}.${field}`];
    }
    if (checkError[obj] && checkError[obj][field]) {
      delete checkError[obj][field];
    }

    setErrors({ ...checkError });
  };

  const changeInput = (e, userType) => {
    let { name, value } = e.target;
    clearErrField(userType, name);

    if (name === "phone") {
      userDispatch({
        type: userActions.setValue,
        payload: { userType, name: formNames.phone, value },
      });
    }

    if(name === "birthday" && value===""){
      const value = null;
      clearErrField(userType, name);
      userDispatch({
        type: userActions.setValue,
        payload: { userType, name, value },
      });
    }
    else {
      userDispatch({
        type: userActions.setValue,
        payload: {userType, name, value},
      });
    }

    setCreateStudentData({
      ...createStudentData,
      [userTypes[userType]]: {
        ...createStudentData[userTypes[userType]],
        [name]: value,
      },
    });
  };

  const changePhone = (phone, userType) => {
    clearErrField(userType, "phone");
    userDispatch({
      type: userActions.setValue,
      payload: { userType, name: formNames.phone, value: phone },
    });
  };

  const uploadImage = (e) => {
    e.preventDefault();
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    const avatar = e.target.files[0];

    if (avatar.size <= SIZE_LIMIT) {
      reader.onload = () => {
        setImage(reader.result);
        setAvatarName(avatar.name);
      };
      reader.readAsDataURL(files[0]);
    } else {
      toast.success("Размер файла не должен превышать 10 МБ", options);
    }
  };

  const getCropData = (e, userType) => {
    setButtonStatus(true);
    setIsCropperButtonPressed(true);
    seCropperModalOpen(false);

    if (typeof cropper !== "undefined") {
      setCropPhoto(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
      fetch(cropper.getCroppedCanvas().toDataURL("image/jpeg"))
        .then((res) => res.blob())
        .then((file) => {
          userDispatch({
            type: userActions.setValue,
            payload: { userType, name: "avatar", value: file },
          });
        });
    }
  };

  const options = {
    style: { width: "400px" },
  };

  const deleteImage = (e, userType) => {
    imageElement.value = "";
    setImage(null);
    setAvatarName(null);
    setCropPhoto(null);
    setButtonStatus(false);
    setIsCropperButtonPressed(false);
    seCropperModalOpen(false);

    userDispatch({
      type: userActions.deletePhoto,
      payload: { userType, name: formNames.avatar, value: "" },
    });
  };

  const submitButton = async () => {
    setIsSubmitBtnPressed(true);
    setLoading(true);

    const validateErrors = await validation.clientValidateForm({ ...user });
    if (validateErrors) {
      window.scrollTo(200, 200);
      setErrors({ ...validateErrors });
      setLoading(false);
    } else {
      userApi
        .createStudent(user, avatarName)
        .then(() => {
          setLoading(false);
          setErrors({});
          toast.success("Пользователь успешно создан", options);
          setUserListTabs("tab2");
          history.push(paths.usersList);
        })
        .catch((error) => {
          const { student, adult } = error.data.error.advanced;
          setErrors({ student, adult });
          setLoading(false);
        });
    }
  };

  const textArea = document.getElementById("other_divergency");
  textArea?.addEventListener("keyup", function () {
    if (this.scrollTop > 0) {
      this.style.height = this.scrollHeight + "px";
    }
  });

  return (
    <>
      <div>
        <div>
          <Form>
            <Row>
              <Col md={5}>
                <p className={style.title}>Ученик </p>
                <FormGroup>
                  <Label for="firstName">Имя ученика:*</Label>
                  <Input
                    type="text"
                    id="firstName"
                    name={formNames.first_name}
                    value={user.student.first_name}
                    placeholder="Введите имя"
                    onChange={(e) => changeInput(e, userTypes.student)}
                    invalid={
                      !!errors["student.first_name"] ||
                      !!errors?.student?.first_name
                    }
                    onBlur={(e) => onBlur(e, userTypes.student)}
                    autoComplete="off"
                  />
                  <FormFeedback>
                    {errors["student.first_name"] ||
                      errors?.student?.first_name}
                  </FormFeedback>
                  <FormText color="muted">
                    Введите от 2 до 150 символов
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={5}>
                <p className={style.title}>Взрослый </p>
                <FormGroup>
                  <Label for="fio">ФИО взрослого:*</Label>
                  <Input
                    type="text"
                    id="fio"
                    name={formNames.fio}
                    value={user.adult.fio}
                    placeholder="Введите ФИО"
                    onChange={(e) => changeInput(e, userTypes.adult)}
                    invalid={!!errors["adult.fio"] || !!errors?.adult?.fio}
                    onBlur={(e) => onBlur(e, userTypes.adult)}
                  />
                  <FormFeedback>
                    {errors["adult.fio"] || errors?.adult?.fio}
                  </FormFeedback>
                  <FormText color="muted">
                    Введите от 2 до 150 символов
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label for="lastName">Фамилия ученика:*</Label>
                  <Input
                    type="text"
                    id="lastName"
                    name={formNames.last_name}
                    value={user.student.last_name}
                    placeholder="Введите фамилию"
                    onChange={(e) => changeInput(e, userTypes.student)}
                    invalid={
                      !!errors["student.last_name"] ||
                      !!errors?.student?.last_name
                    }
                    onBlur={(e) => onBlur(e, userTypes.student)}
                    autoComplete="off"
                  />
                  <FormFeedback>
                    {errors["student.last_name"] || errors?.student?.last_name}
                  </FormFeedback>
                  <FormText color="muted">
                    Введите от 2 до 150 символов
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for="birthdayAdult">Дата рождения:</Label>
                  <Input
                    type="date"
                    max="9999-12-31"
                    id="birthdayAdult"
                    name={formNames.birthdayAdult}
                    value={user.adult.birthday}
                    onChange={(e) => changeInput(e, userTypes.adult)}
                    // invalid={
                    //   !!errors["adult.birthday"] || !!errors?.adult?.birthday
                    // }
                    // onBlur={(e) => onBlur(e, userTypes.adult)}
                  />
                  <FormFeedback>
                    {errors["adult.birthday"] || errors?.adult?.birthday}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label for="birthdayStudent">Дата рождения:*</Label>
                  <Input
                    type="date"
                    max="9999-12-31"
                    id="birthdayStudent"
                    name={formNames.birthdayStudent}
                    value={user.student.birthday}
                    onChange={(e) => changeInput(e, userTypes.student)}
                    invalid={
                      !!errors["student.birthday"] ||
                      !!errors?.student?.birthday
                    }
                    onBlur={(e) => onBlur(e, userTypes.student)}
                  />
                  <FormFeedback>
                    {errors["student.birthday"] || errors?.student?.birthday}
                  </FormFeedback>
                  <div className={style.studentAge}>
                    Возраст: {age.detectedAgeFromDate(user?.student?.birthday)}
                  </div>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for="role">Роль:*</Label>
                  <Input
                    type="select"
                    id="role"
                    name={formNames.role}
                    value={user.adult.role}
                    onChange={(e) => changeInput(e, userTypes.adult)}
                    invalid={!!errors["adult.role"] || !!errors?.adult?.role}
                    onBlur={(e) => onBlur(e, userTypes.adult)}
                  >
                    {adultRole?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>
                    {errors["adult.role"] || errors?.adult?.role}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label for="divergency">Особенность:*</Label>
                  <Input
                    type="select"
                    id="divergency"
                    name={formNames.divergency}
                    value={user?.student?.divergency}
                    onChange={(e) => changeInput(e, userTypes.student)}
                    invalid={
                      !!errors["student.divergency"] ||
                      !!errors?.student?.divergency
                    }
                    onBlur={(e) => onBlur(e, userTypes.student)}
                  >
                    <option value="" selected disabled hidden>
                      Выберите особенность
                    </option>
                    {divergencies?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>
                    {errors["student.divergency"] ||
                      errors?.student?.divergency}
                  </FormFeedback>
                </FormGroup>
                {user.student.divergency === "7" && (
                  <>
                    <div className={style.otherDivergency}>
                      <Input
                        type="textarea"
                        id="other_divergency"
                        name={formNames.other_divergency}
                        value={user.student.other_divergency}
                        placeholder="Опишите вашу особенность"
                        onChange={(e) => changeInput(e, userTypes.student)}
                        invalid={
                          !!errors["student.other_divergency"] ||
                          !!errors?.student?.other_divergency
                        }
                        className={style.otherDivergency_input}
                        onBlur={(e) => onBlur(e, userTypes.student)}
                        autoComplete="off"
                      ></Input>
                      <FormFeedback>
                        {errors["student.other_divergency"] ||
                          errors?.student?.other_divergency}
                      </FormFeedback>
                      <FormText color="muted">
                        Введите от 2 до 200 символов
                      </FormText>
                    </div>
                  </>
                )}
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for="location">Место проживания:*</Label>
                  <Input
                    type="text"
                    id="location"
                    name={formNames.location}
                    onSearch={handleCitySearch}
                    onChange={(e) => changeInput(e, userTypes.adult)}
                    onBlur={(e) => onBlur(e, userTypes.adult)}
                    options={city}
                    placeholder="Введите страну и город"
                    isInvalid={
                      !!errors["adult.location"] || !!errors?.adult?.location
                    }
                  />
                  <FormFeedback
                    className={
                      (!!errors["adult.location"] || errors?.adult?.location) &&
                      "d-block"
                    }
                  >
                    {errors["adult.location"] || errors?.adult?.location}
                  </FormFeedback>
                  <FormText color="muted">
                    Введите от 2 до 150 символов
                  </FormText>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label for="educationClass">Класс обучения:</Label>
                  <FormSelect
                    type="text"
                    id="educationClass"
                    name={formNames.education_class}
                    value={user.student.education_class}
                    onChange={(e) => changeInput(e, userTypes.student)}
                  >
                    <option>Выберите класс</option>
                    {studentClasses?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </FormSelect>
                </FormGroup>

                {/* <FormGroup>
                  <Label for="educationFormat">Форма обучения:</Label>
                  <FormSelect
                    type="text"
                    id="educationFormat"
                    name={formNames.education_format}
                    value={user.student.education_format}
                    onChange={(e) => changeInput(e, userTypes.student)}>
                    <option>Выберите форму обучения</option>
                    {studentEducationForm?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </FormSelect>
                </FormGroup> */}
                <FormGroup>
                  <Label for="phone">Телефон:</Label>
                  <Input
                    className={style.raz}
                    type="tel"
                    id="phone"
                    placeholder="Введите номер телефона"
                    name={formNames.phone}
                    value={user.student.phone}
                    isValid={() =>
                      !errors["student.phone"] || !!errors?.student?.phone
                    }
                    onChange={(e) => changeInput(e, userTypes.student)}
                  />
                  {/* <PhoneInput
                    inputStyle={{ width: 450 }}
                    id="phone"
                    name={formNames.phone}
                    country={'ru'}
                    value={user.student.phone}
                    onlyCountries={country}
                    countryCodeEditable={false}
                    onChange={(e, phone) => changePhone(e, userTypes.student, { phone })}
                    isValid={() => !errors['student.phone'] || !!errors?.student?.phone}
                    onBlur={(e) => onBlurPhone(e, userTypes.student)}
                  /> */}
                  <FormFeedback
                    className={
                      (!!errors["student.phone"] || !!errors?.student?.phone) &&
                      "d-block"
                    }
                  >
                    {errors["student.phone"] || errors?.student?.phone}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="email">Почта:*</Label>
                  <Input
                    type="text"
                    id="email"
                    name={formNames.email}
                    value={user.student.email}
                    onChange={(e) => changeInput(e, userTypes.student)}
                    invalid={!!errors?.["student.email"]}
                    onBlur={(e) => onBlur(e, userTypes.student)}
                    placeholder="Введите почту"
                  />
                  <FormFeedback>{errors?.["student.email"]}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>Фото:</Label>
                  {buttonStatus ? (
                    <>
                      <div className={style.inputImgWrapper}>
                        <img
                          className={style.inputImgWrapperImg}
                          src={imgIcon}
                          alt="icon"
                        />
                        <p className={style.inputImgWrapperTxt}>{avatarName}</p>

                        <Button
                          className={style.inputImgWrapperBtn}
                          outline
                          color="inherit"
                          onClick={(e) => deleteImage(e, userTypes.student)}
                        >
                          <img src={deleteIcon} alt="icon" />
                        </Button>
                      </div>
                      <img
                        src={cropPhoto}
                        alt=""
                        className={style.inputImage}
                      />
                    </>
                  ) : (
                    <Button
                      color="inherit"
                      className={style.fileLable}
                      onClick={() => seCropperModalOpen(true)}
                    >
                      <img
                        className={style.addImageButton}
                        color="inherit"
                        src={downloadLogo}
                        alt=""
                      />
                      Прикрепить изображение
                    </Button>
                  )}

                  <div className={style.blockPhoto}>
                    <input
                      id="avatar"
                      type="file"
                      onChange={uploadImage}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                  </div>

                  <Label className={style.inputImgWrapperLable}>
                    Размер файла не должен превышать 10 МБ
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
        {loading ? (
          <Button
            disabled
            color="primary"
            className={style.createLesson}
            onClick={() => submitButton()}
          >
            <div
              className={`spinner-border text-light ${style.loading}`}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </Button>
        ) : (
          <Button
            color="primary"
            className={style.createLesson}
            onClick={() => submitButton()}
          >
            Добавить
          </Button>
        )}
      </div>

      {cropperModalOpen && (
        <CropperModal
          image={image}
          setCropper={setCropper}
          getCropData={getCropData}
          userTypes={userTypes}
          cancelClick={deleteImage}
          lableFor={"avatar"}
          croppSize={10}
          hint="Выберите область, которая будет отображаться в вашем профиле"
          ratio={1}
        />
      )}
    </>
  );
}

export default observer(AddStudent);
