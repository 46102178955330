import React, { useEffect } from "react";
import * as style from "./style/AdminPanel.module.scss";
import { ReactComponent as ArrowIcon } from "assets/img/arrow.svg";
import role from "constants/role";
import { useStore } from "store";
import paths from "constants/paths";

export default function AdminPanel() {
    const {
        createLessonStore: { setIsAdd },
    } = useStore();

    const isAdmin = localStorage.getItem("role");

    useEffect(() => {
        setIsAdd(false);
    }, []);

    return (
        <div className={style.admin_panel__container}>
            <div>
                <h1 className={`title-h1 ${style.admin_panel__head}`}>Личный кабинет</h1>
                <div className={style.admin_panel__links}>
                    <a href={paths.lessonList} className={style.admin_panel__link}>
                        <h3 className="title-h3">Управление уроками</h3>
                        <div>
                            <ArrowIcon className={style.imageArrow} />
                        </div>
                    </a>
                    <a href={paths.usersList} className={style.admin_panel__link}>
                        <h3 className="title-h3">Управление пользователями</h3>
                        <div>
                            <ArrowIcon className={style.imageArrow} />
                        </div>
                    </a>
                    {isAdmin === role.admin && (
                        <a href={paths.itemsManagement} className={style.admin_panel__link}>
                            <h3 className="title-h3">Управление предметами</h3>
                            <div>
                                <ArrowIcon className={style.imageArrow} />
                            </div>
                        </a>
                    )}
                    <a href={paths.resources()} className={style.admin_panel__link}>
                        <h3 className="title-h3">Каталог ресурсов</h3>
                        <div>
                            <ArrowIcon className={style.imageArrow} />
                        </div>
                    </a>
                    {isAdmin === role.admin && (
                        <a href={paths.educationForms} className={style.admin_panel__link}>
                            <h3 className="title-h3">Шаблоны форм обучения</h3>
                            <div>
                                <ArrowIcon className={style.imageArrow} />
                            </div>
                        </a>
                    )}
                    <a href={paths.purchases} className={style.admin_panel__link}>
                        <h3 className="title-h3">Покупки</h3>
                        <div>
                            <ArrowIcon className={style.imageArrow} />
                        </div>
                    </a>
                    {isAdmin === role.admin && (
                        <a href={paths.additionalPurchases} className={style.admin_panel__link}>
                            <h3 className="title-h3">Магазин дополнительных продуктов</h3>
                            <div>
                                <ArrowIcon className={style.imageArrow} />
                            </div>
                        </a>
                    )}
                    {isAdmin === role.mentor && (
                        <a href={paths.timeSheet} className={style.admin_panel__link}>
                            <h3 className="title-h3">Календарь</h3>
                            <div>
                                <ArrowIcon className={style.imageArrow} />
                            </div>
                        </a>
                    )}
                </div>
                <a href={paths.changePassword} className={style.link__change_password}>
                    Сменить пароль
                </a>
            </div>
        </div>
    );
}
