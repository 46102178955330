import { makeAutoObservable } from "mobx";
import { compareData } from "utils/compareData";

class UserStore {
    constructor() {
        this.isAuthorized = !!localStorage.getItem("access_token");
        this.trainingNames = [];
        this.userListTabs = "tab1";
        this.creacteUserTabs = "tab1";
        this.isOpenLeaveModal = false;
        makeAutoObservable(this);
    }

    setIsAuthorized = (value) => {
        this.isAuthorized = value;
    };

    setTrainingNames = (value) => {
        this.trainingNames = value;
    };

    setUserListTabs = (value) => {
        this.userListTabs = value;
    };

    setCreateUserTabs = (value) => {
        this.creacteUserTabs = value;
    };

    setOpenModalLeaveOpen = (value) => {
        this.isOpenLeaveModal = value;
    };

    checkForOpenModalTeacher = (userTeacher, defaultTeacherData) => {
        const prepareTeacher = {...compareData(userTeacher, defaultTeacherData) };
        if (Object.keys(prepareTeacher).length > 0) {
            return true;
        } else {
            return false;
        }
    };

    checkForOpenModalStudent = (
        studentData,
        studentDefault,
        adultData,
        adultDefault
    ) => {
        const prepareStudent = {...compareData(studentData, studentDefault) };
        const prepareAdult = {...compareData(adultData, adultDefault) };
        if (
            Object.keys(prepareStudent).length > 0 ||
            Object.keys(prepareAdult).length > 0
        ) {
            return true;
        } else {
            return false;
        }
    };
}

export default UserStore;