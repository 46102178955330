import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { FormLabel } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";

import { MultiSelect } from "react-multi-select-component";
import users from "../../../api/endPoints/users";
import FileSaver from "file-saver";

import * as style from "./style/StudentsList.module.scss";
import closeIcon from "../../../assets/img/closeIcon.svg";

import StudentComponent from "./StudentComponent";
import { ReactComponent as SearchIcon } from "../../../assets/img/search.svg";
import { ReactComponent as Download } from "../../../assets/img/downloadBlue.svg";

import role from "constants/role";

const isAdmin = localStorage.getItem('role') === role.admin;
function StudentsList() {
  const [classesStudent, setClassesStudent] = useState([
    { label: "Не указано", value: 0 },
  ]);

  const [educationFormStudent, setEducationFormStudent] = useState([]);

  const [filter, setFilters] = useState({
    name: "",
    education_formats: [],
    education_classes: [],
  });

  const [displayResetButton, setDisplayResetButton] = useState(false);

  useEffect(() => {
    users.getStudentClass().then((response) => {
      const getStudentClasses = response.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setClassesStudent([...classesStudent, ...getStudentClasses]);
    });

    users.getStudentEducationForm().then((response) => {
      const getStudentForms = response.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setEducationFormStudent([...educationFormStudent, ...getStudentForms]);
    });
  }, []);

  const onSelectEducationForm = (selectedValues) => {
    let newFilters = { ...filter };
    newFilters["education_formats"] = selectedValues;
    setFilters(newFilters);
    if (
      newFilters.education_formats.length > 0 ||
      newFilters.education_classes.length > 0
    ) {
      setDisplayResetButton(true);
    } else {
      setDisplayResetButton(false);
    }
  };

  const onSelectEducationClasses = (selectedValues) => {
    let newFilters = { ...filter };
    newFilters["education_classes"] = selectedValues;
    setFilters(newFilters);
    if (
      newFilters.education_classes.length > 0 ||
      newFilters.education_formats.length > 0
    ) {
      setDisplayResetButton(true);
    } else {
      setDisplayResetButton(false);
    }
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setFilters({ education_formats: [], education_classes: [] });
    setDisplayResetButton(false);
  };

  const downloadStudents = () => {
    users
      .downloadStudents(filter)
      .then((response) => FileSaver.saveAs(response, "Список учеников.xlsx"));
  };

  const localization = {
    selectSomeItems: "Все",
    allItemsAreSelected: "Все",
    selectAll: "Все",
    search: "Поиск",
  };

  return (
    <div>
      <div>
        <div className={style.listHeader}>
          <div className={style.listHeaderInput}>
            <p className={style.listHeaderInput_title}>Поиск по ФИО:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                name="name"
                className={style.findUsers}
                value={filter.name}
                minLength={1}
                debounceTimeout={800}
                onChange={(event) =>
                  setFilters({ ...filter, name: event.target.value.trim("-") })
                }
                placeholder="Введите ФИО ученика"
              />
              <div className={style.searchImg}>
                <SearchIcon />
              </div>
            </div>
          </div>
          {isAdmin && (
              <Button
                  color="inherit"
                  className={style.fileLable}
                  onClick={downloadStudents}
              >
                <Download />
                <p className={style.fileLable_text}>Скачать список</p>
              </Button>
          )}
        </div>

        <Form className={style.findEducationForm}>
          <FormGroup>
            <FormLabel className={style.titleEducationForm}>
              Форма обучения:
            </FormLabel>
            <MultiSelect
              hasSelectAll={false}
              disableSearch
              options={educationFormStudent}
              onChange={onSelectEducationForm}
              value={filter.education_formats}
              className={style.education_formats}
              overrideStrings={localization}
            />
          </FormGroup>
          <FormGroup className={style.selectTeacher}>
            <Label className={style.titleEducationClass}>Класс обучения</Label>
            <MultiSelect
              hasSelectAll={false}
              disableSearch
              options={classesStudent}
              onChange={onSelectEducationClasses}
              value={filter.education_classes}
              className={style.education_classes}
              overrideStrings={localization}
            />
          </FormGroup>

          {displayResetButton && (
            <FormGroup className={style.resetFilter}>
              <button className={style.resetFilterButton} onClick={resetFilter}>
                Сбросить фильтр
                <img
                  className={style.resetFilterButtonIcon}
                  src={closeIcon}
                  alt=""
                />
              </button>
            </FormGroup>
          )}
        </Form>
      </div>
      <StudentComponent filter={filter} />
    </div>
  );
}
export default StudentsList;
