export const compareData = (state = {}, prop = {}) => {
  const temp = {};
  const stateKeys = Object.keys(state);
  const propKeys = Object.keys(prop);
  const stateValues = Object.values(state);
  const propValues = Object.values(prop);

  /* eslint-disable no-plusplus */
  for (let index = 0; index < stateKeys.length; index++) {
    if (stateKeys[index] === propKeys[index]) {
      if (JSON.stringify(stateValues[index]) !== JSON.stringify(propValues[index])) {
        temp[stateKeys[index]] = stateValues[index];
      }
    }
  }
  return temp;
};
