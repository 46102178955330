export default {
    login: "/",
    adminPanel: "/admin",
    training: "/training",
    resource: (id) => `/training/${id}`,
    createMaterial: (id) => `/training/${id}/create`,
    lessonList: "/lessons",
    usersList: "/users",
    addUsers: "/add-users",
    timeSheet: "/time-sheet",
    createLesson: "/create-lesson",
    editLesson: (id) => `/lesson/${id}`,
    editTeacher: (id) => `/edit-teacher/${id}`,
    editStudent: (id) => `/edit-student/${id}`,
    changePassword: "/change-password",
    resources: () => "/resources",
    folderDetail: (id) => `/folder-detail/${id}`,
    purchases: "/purchases",
    userDetail: (id) => `/user-detail/${id}`,
    teacherDetail: (id) => `/teacher-detail/${id}`,
    educationForms: "/education-forms",
    educationFormsDetail: (id) => `/education-forms/${id}`,
    createNewForm: (id) => `/education-forms/${id}/create-form`,
    editForm: (id, formId) => `/user/${id}/edit-form/${formId}`,
    purchaseHistory: (id) => `/purchase-history/${id}`,
    itemsManagement: "/items-management",
    additionalPurchases: "/additional-purchases",
    additionalPurchasesDetail: (id) => `/additional-purchases/materials/${id}`,
    resources: () => "/resources",
    folderDetail: (id) => `/folder-detail/${id}`,
    addNewProduct: (id) => `/additional-purchases/materials/${id}/add-product`,
    purchaseSubjects: (id) => `/additional-purchases/material/${id}`,
};
