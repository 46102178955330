import React from "react";
import View from "./View.jsx";

const Controller = (props) => {
    const { lesson } = props;

    return <View lessons={lesson} />;
};

export default Controller;
