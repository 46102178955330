import axiosInstance from "..";
import apiList from "../../constants/apiList";
import { userTypes } from "../../components/UsersList/AddUsers/AddStudent/reducers/userReducer.js";
import { isUndefined } from "lodash";

//eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getTeachersList(page, filter) {
        const formatterFilter = { ...filter };

        if (formatterFilter.name === "") {
            delete formatterFilter.name;
        }
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getTeachersList,
                params: {
                    filter: formatterFilter,
                    page: page,
                    per_page: "25",
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getStudentsList(filter, page) {
        const formatterFilter = { ...filter };

        if (formatterFilter.name === "") {
            delete formatterFilter.name;
        }
        if (
            formatterFilter.education_classes[0] === "Все" ||
            formatterFilter.education_classes.length === 0
        ) {
            delete formatterFilter.education_classes;
        } else {
            const newClasses = formatterFilter.education_classes.map((item) => item.label);
            formatterFilter.education_classes = newClasses;
        }
        if (
            formatterFilter.education_formats[0] === "Все" ||
            formatterFilter.education_formats.length === 0
        ) {
            delete formatterFilter.education_formats;
        } else {
            const newFormats = formatterFilter.education_formats.map((item) => item.label);
            formatterFilter.education_formats = newFormats;
        }

        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getStudentsList,
                params: {
                    filter: formatterFilter,
                    page: page,
                    per_page: "25",
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getStudent(id) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getStudent(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async createTeacher(data, avatarName) {
        let formData = new FormData();

        if (data.avatar) {
            formData.append("avatar", data.avatar, avatarName);
        }

        delete data.avatar;

        formData.append(
            "jsonBody",
            JSON.stringify({
                ...data,
            }),
        );

        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.createTeacher,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async createStudent(data, avatarName) {
        let formData = new FormData();

        const { avatar, ...studentData } = data.student;

        if (
            studentData.education_format === "" ||
            studentData.education_format === "Выберите форму обучения"
        ) {
            delete studentData.education_format;
        }
        if (
            studentData.education_class === "" ||
            studentData.education_class === "Выберите класс"
        ) {
            delete studentData.education_class;
        }

        if (avatar) {
            formData.append("avatar", avatar, avatarName);
            delete studentData.avatar;
        }

        formData.append(
            "jsonBody",
            JSON.stringify({
                ...data,
            }),
        );

        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.createStudent,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editStudent(data, id, deleteAvatar, avatarName) {
        let formData = new FormData();

        const { avatar, ...studentData } = data.student;

        if (
            studentData.education_format === "" ||
            studentData.education_format === "Выберите форму обучения" ||
            studentData.education_format === 0
        ) {
            studentData.education_format = null;
        }
        if (
            studentData.education_class === "" ||
            studentData.education_class === "Выберите класс" ||
            studentData.education_class === 0
        ) {
            studentData.education_class = null;
        }

        if (avatar) {
            formData.append("avatar", avatar, avatarName);
            delete studentData.avatar;
        }

        formData.append(
            "jsonBody",
            JSON.stringify({
                ...data,
            }),
        );

        if (deleteAvatar) {
            formData.append("delete_avatar", true);
        }

        formData.append("_method", "PUT");

        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editStudent(id),
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getStudentDevergency() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getStudentDevergency,
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getStudentClass() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getStudentClass,
            });
            return response.data.classes;
        } catch (error) {
            throw error.response;
        }
    },

    async getStudentEducationForm() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getStudentEducationForm,
            });
            return response.data.data.items;
        } catch (error) {
            throw error.response;
        }
    },

    async getAdultRole() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getAdultRole,
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getCities(event) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getCities,
                params: {
                    city_name: event,
                },
            });

            return response.data.data.items;
        } catch (error) {
            throw error.response;
        }
    },

    async deleteUser(id) {
        try {
            const response = await axiosInstance({
                method: "DELETE",
                url: apiList.deleteUser(id),
            });
            return response;
        } catch (error) {
            throw error.message;
        }
    },

    async blockTeacher(id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.blockUser(id),
            });
            return response;
        } catch (error) {
            throw error.message;
        }
    },

    async getTeacher(id) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getTeacher(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editTeacher(data, id, deleteAvatar, avatarName) {
        let formData = new FormData();

        if (data.avatar) {
            formData.append("avatar", data.avatar, avatarName);
            delete data.avatar;
        }
        if (data.avatar === null) {
            delete data.avatar;
        }

        formData.append(
            "jsonBody",
            JSON.stringify({
                ...data,
            }),
        );

        if (deleteAvatar) {
            formData.append("delete_avatar", true);
        }

        formData.append("_method", "PUT");

        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editTeacher(id),
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async unblockUser(id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.undlockUser(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async downloadStudents(filter) {
        const formatterFilter = { ...filter };

        if (formatterFilter.name === "") {
            delete formatterFilter.name;
        }

        if (!formatterFilter.education_formats.length) {
            delete formatterFilter.education_formats;
        } else {
            const newFormats = formatterFilter.education_formats.map((item) => item.label);
            formatterFilter.education_formats = newFormats;
        }

        if (!formatterFilter.education_classes.length) {
            delete formatterFilter.education_classes;
        } else {
            const newClasses = formatterFilter.education_classes.map((item) => item.label);
            formatterFilter.education_classes = newClasses;
        }

        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.downloadStudents,
                responseType: "blob",
                params: {
                    filter: formatterFilter,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async downloadMentors(filter) {
        const formatterFilter = { ...filter };

        if (formatterFilter.name === "") {
            delete formatterFilter.name;
        }

        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.downloadMentors,
                responseType: "blob",
                params: {
                    filter: formatterFilter,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getNoCountableElementsForUser(id) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getNoCountableElementsForUser,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getNoCountableElementsForForm(id) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getNoCountableElementsForForm(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async changeCountableElements(itemId, count, id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.changeCountableElements(id),
                data: {
                    countable_element_id: itemId,
                    count,
                },
                params: {
                    _method: "PUT",
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getStudentsFilter(filter, page) {
        const formatterFilter = { ...filter };

        if (formatterFilter.name === "") {
            delete formatterFilter.name;
        }

        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getStudentsList,
                params: {
                    filter: formatterFilter,
                    page: page,
                    per_page: "8",
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },
};
