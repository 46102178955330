import React from "react";
import styles from "./styles.module.scss";

const View = (props) => {
    const { lesson } = props;

    return <div>norification</div>;
};

export default View;
